import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { api } from '@/utils/api';
import { ApiErrorResponse, ApiResponse } from '@/types/api';
import { UserInfo } from '@/types/user';

interface GetUserInfoResponse {
  user: UserInfo;
}

export const getUserInfo = () => {
  return api.get<ApiResponse<GetUserInfoResponse>>('/user');
};

const useUserInfo = <
  TData = AxiosResponse<ApiResponse<GetUserInfoResponse>>,
  TError = AxiosError<ApiErrorResponse>,
>(
  options?: UseQueryOptions<
    AxiosResponse<ApiResponse<GetUserInfoResponse>>,
    TError,
    TData,
    ['getUserInfo']
  >,
): UseQueryResult<TData, TError> => {
  return useQuery(['getUserInfo'], getUserInfo, {
    enabled: false,
    retry: false,
    ...options,
  });
};

export default useUserInfo;
