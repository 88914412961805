import type { NextPage } from 'next';
import axios from 'axios';
import { useRouter } from 'next/router';

import useUserToken from '@/hooks/queries/auth/useUserToken';
import useUserInfo from '@/hooks/queries/user/useUserInfo';

const Home: NextPage = () => {
  const router = useRouter();
  const { refetch: refetchUserToken } = useUserToken({
    onSuccess: () => {
      router.replace('/user/pdf');
    },
    onError: () => {
      router.replace('/login');
    },
  });

  useUserInfo({
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      router.push('/user/pdf');
    },
    onError: (error) => {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { status } = error.response;
        if (status === 403) {
          refetchUserToken();
        }
      }
    },
  });

  return <></>;
};

export default Home;
