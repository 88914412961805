import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { api } from '@/utils/api';
import { ApiErrorResponse, ApiResponse } from '@/types/api';

export const getUserToken = () => {
  return api.get<ApiResponse<undefined>>('/auth/token');
};

const useUserToken = <
  TData = AxiosResponse<ApiResponse<undefined>>,
  TError = AxiosError<ApiErrorResponse>,
>(
  options?: UseQueryOptions<
    AxiosResponse<ApiResponse<undefined>>,
    TError,
    TData,
    ['getUserToken']
  >,
): UseQueryResult<TData, TError> => {
  return useQuery(['getUserToken'], getUserToken, {
    enabled: false,
    retry: false,
    ...options,
  });
};

export default useUserToken;
